import React, { Component } from 'react'
import {
    Col,
    Row,
} from 'reactstrap';
import frozberrieswa_logo from '../images/frozberries-logo.png'
import PriceList from './PriceList';
class Landing extends Component {


    render() {
        return (

            <div className='bg ' style={{ minHeight: '100vh' }}>
                <div className='overlay d-flex align-items-center' style={{ minHeight: '100vh' }}>
                    <Row className='h-100  mx-auto'>
                        <Col className='text-center  my-auto ' style={{ paddingRight: '10%', paddingLeft: '10%' }}>
                            <img src={frozberrieswa_logo} className='img-fluid' alt='frozberrieswa logo' />
                            <h1 className='pt-3 text-light' style={{ fontSize: 37, fontWeight: 700 }}>OUR WEBSITE IS COMING SOON</h1>
                            <div className='text-light' style={{ fontSize: 20, paddingLeft: '10%', paddingRight: '10%' }}>We're building an ecommerce website with online ordering. Please check back later. In the mean time connect with us with the options below.
                            <PriceList />

                            </div>

                            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                <p className='text-light'>
                                    <i className="fas fa-home mr-2 text-light" ></i> 9 Industry St, Malaga WA 6090</p>
                                <p className='text-light '>
                                    <a className='text-light d-inline' href='mailto:sales@frozberrieswa.com.au'>
                                        <i className="fas fa-envelope mr-2 d-inline " ></i>
                                        sales@frozberrieswa.com.au
                                </a></p>
                                <p className='text-light'>
                                    <a className='text-light' href='tel:08 9248 2673'>
                                        <i className="fas fa-phone mr-2 " ></i> 08 9248 2673
                                </a> </p>
                                <p className='text-light'>
                                    <i className="fas fa-print mr-2 " ></i> 08 9248 4675</p>
                            </div>


                            <div className="text-center ">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/FrozBerriesWA/" className="btn-floating btn-sm rgba-white-sdark mx-1">
                                            <i className="fab fa-facebook-f text-light fa-2x"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/frozberries_wa/" className="btn-floating btn-sm rgba-white-sdark mx-1">
                                            <i className="fab fa-instagram text-light fa-2x"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default Landing