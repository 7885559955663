import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import pdf from '../Frozberries_WA_Product_List.pdf'

export default class PriceList extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <div className='text-center py-2 '>
                            <a href={pdf} target="_blank" rel="noopener noreferrer" className='text-primary' style={{ cursor: 'pointer', textDecoration:'underline',  }}>
                                You can also access our price list by clicking here.
                        </a>
                        </div>
                        
                    </Col>
                </Row>
            </div>
        )
    }
}
