import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Landing from './Components/Landing';

function App() {
  return (
    <div className="App  site" >
      <div className='site-content'>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Landing} />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
